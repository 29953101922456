import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import PageTemplate from "../templates/pageTemplate"

const Blog = ({ data }) => {
  const edges = data.allMarkdownRemark.edges
  return (
    <PageTemplate title="Blog">
      {edges.length < 0 ? (
        <div>No posts yet</div>
      ) : (
        <List>
          {edges.map((edge) => (
            <>
              <Item>
                <div>
                  <Link to={edge.node.fields.slug}>
                    <h2>{edge.node.frontmatter.title}</h2>
                  </Link>
                  <small>{edge.node.frontmatter.date}</small>
                </div>
                <p>{edge.node.excerpt}</p>
              </Item>
            </>
          ))}
        </List>
      )}
    </PageTemplate>
  )
}

export default Blog

const List = styled.ul`
  list-style-type: none;
`

const Item = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 600px;
  padding: 1em;
`

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/(blog)/" } }) {
      edges {
        node {
          id
          frontmatter {
            date
            title
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 630) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
