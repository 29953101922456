import React from "react"
import styled from "styled-components"

import Layout from "../../components/common/layout/layout"
import SEO from "../../components/common/layout/seo"
import Navigation from "../../components/common/navigation/navigation"

import Header from "../../components/common/header"
import Footer from "../../components/common/footer"

const PageTemplate = ({ title, children: content }) => (
  <>
    <Layout>
      <SEO title={title} />
      <Navigation />
      <Header>
        <PageTitle>{title}</PageTitle>
      </Header>
      <PageContent>{content}</PageContent>
      <Footer />
    </Layout>
  </>
)

const PageTitle = styled.h1`
  color: ${({ theme }) => theme.color.white.regular};
`

const PageContent = styled.div`
  max-width: 800px;
  min-height: 600px;
  padding: 1em;
  margin: 3em auto;
  text-align: justify;
  line-height: 2;
  color: ${({ theme }) => theme.color.black.lightest};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme }) => theme.color.black.light};
    text-align: left;
  }
`

export default PageTemplate
